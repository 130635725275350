<script>
  import DatePicker from '../date-picker/DatePicker.svelte'

  import Validator, {VALIDATION_SUCCESS} from '../../services/Validator.js'

  export let doc = {}
  export let key = ''
  export let isValid = true

  let will_relocate = true
  let jobtype_parttime = false
  let jobtype_fulltime = false
  let jobtype_contract = false
  let jobtype_seasonal = false

  if ($doc[key]) {
    will_relocate = $doc[key]['will_relocate']
    jobtype_parttime = $doc[key]['jobtype_parttime']
    jobtype_fulltime = $doc[key]['jobtype_fulltime']
    jobtype_contract = $doc[key]['jobtype_contract']
    jobtype_seasonal = $doc[key]['jobtype_seasonal']
  }

  $doc[key] = $doc[key] || {}

  let currentDate =
    $doc[key].start_date === undefined
      ? new Date()
      : new Date($doc[key].start_date) === 'Invalid Date'
      ? new Date()
      : new Date($doc[key].start_date)
  // let currentDate = new Date();

  $: console.log(currentDate)

  $: $doc[key]['will_relocate'] = will_relocate
  $: $doc[key]['jobtype_parttime'] = jobtype_parttime
  $: $doc[key]['jobtype_fulltime'] = jobtype_fulltime
  $: $doc[key]['jobtype_contract'] = jobtype_contract
  $: $doc[key]['jobtype_seasonal'] = jobtype_seasonal

  const onDateChange = d => {
    $doc[key].start_date = d.detail
    currentDate = d.detail
  }

  const iniial_validation_state = VALIDATION_SUCCESS

  let firstNameValidation = iniial_validation_state
  let lastNameValidation = iniial_validation_state
  let emailValidation = iniial_validation_state
  let phoneNumberValidation = iniial_validation_state
  let streetAddressValidation = iniial_validation_state
  let cityValidation = iniial_validation_state
  let provinceValidation = iniial_validation_state
  let postalCodeValidation = iniial_validation_state
  let accountingCertificationsValidation = iniial_validation_state
</script>

<form class="h-auto space-y-8 divide-y divide-gray-200">
  <div class="space-y-8 divide-y divide-gray-200">
    <div>
      <div class="pt-8">
        <h1 class="text-l font-semibold text-fiscal-green-500">
          Your Information
        </h1>
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label
              for="given_name"
              class="block text-sm font-medium text-gray-700"
            >
              First name
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="given_name"
                id="given_name"
                autocomplete="given-name"
                bind:value={$doc[key].given_name}
                on:focusout={() => {
                  firstNameValidation = Validator.validateName(
                    $doc[key].given_name
                  )
                }}
                class:validField={firstNameValidation.success}
                class:invalidField={!firstNameValidation.success}
              />
              {#if !firstNameValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            {#if !firstNameValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {firstNameValidation.err_message}
              </p>
            {/if}
          </div>

          <div class="sm:col-span-3">
            <label
              for="family_name"
              class="block text-sm font-medium text-gray-700"
            >
              Last name
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="family_name"
                id="family_name"
                autocomplete="family-name"
                bind:value={$doc[key].family_name}
                on:focusout={() => {
                  lastNameValidation = Validator.validateName(
                    $doc[key].family_name
                  )
                }}
                class:validField={lastNameValidation.success}
                class:invalidField={!lastNameValidation.success}
              />
              {#if !lastNameValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            {#if !lastNameValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {lastNameValidation.err_message}
              </p>
            {/if}
          </div>

          <div class="sm:col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                bind:value={$doc[key].email}
                on:focusout={() => {
                  emailValidation = Validator.validateEmail($doc[key].email)
                }}
                class:validField={emailValidation.success}
                class:invalidField={!emailValidation.success}
              />
              {#if !emailValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            {#if !emailValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {emailValidation.err_message}
              </p>
            {/if}
          </div>

          <!-- The following is a mock field, that was provided by Jon Friesen, which is used as a mock reference to build the other fields when a field is validated to be invalid. Commet it back in to see it, when commenting it back in comment out the heroiconname line as it was supposed to be commented out but isn't due to commenting in-out technicalities -->

          <!-- <div class="sm:col-span-4">
            <label for="email" class="block text-sm font-medium text-gray-700">
              mock field (to be removed)
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="email"
                id="email-mock"
                class="block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                placeholder="you@example.com"
                value="adamwathan"
                aria-invalid="true"
                aria-describedby="email-error"
              />
              <div
                class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
              >
                Heroicon name: solid/exclamation-circle
                <svg
                  class="h-5 w-5 text-red-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </div>
            <p class="mt-2 text-sm text-red-600" id="email-error">
              Your password must be less than 4 characters.
            </p>
          </div> -->

          <div class="sm:col-span-4">
            <label for="phone" class="block text-sm font-medium text-gray-700">
              Phone
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                id="phone"
                name="phone"
                type="text"
                autocomplete="phone"
                placeholder="(604) 555-1234"
                bind:value={$doc[key].phone}
                on:focusout={() => {
                  phoneNumberValidation = Validator.validatePhoneNumber(
                    $doc[key].phone
                  )
                }}
                class:validField={phoneNumberValidation.success}
                class:invalidField={!phoneNumberValidation.success}
              />
              {#if !phoneNumberValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            {#if !phoneNumberValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {phoneNumberValidation.err_message}
              </p>
            {/if}
          </div>

          <div class="sm:col-span-3">
            <label
              for="country"
              class="block text-sm font-medium text-gray-700"
            >
              Country / Region
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <select
                id="country"
                name="country"
                autocomplete="country"
                bind:value={$doc[key].country}
                class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
              >
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div>
          </div>

          <div class="sm:col-span-6">
            <label
              for="street_address"
              class="block text-sm font-medium text-gray-700"
            >
              Street address
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="street_address"
                id="street_address"
                autocomplete="street-address"
                bind:value={$doc[key].street_address}
                class:validField={streetAddressValidation.success}
                class:invalidField={!streetAddressValidation.success}
              />
            </div>
          </div>

          <div class="sm:col-span-2">
            <label for="city" class="block text-sm font-medium text-gray-700">
              City
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="city"
                id="city"
                bind:value={$doc[key].city}
                on:focusout={() => {
                  cityValidation = Validator.validateCity($doc[key].city)
                }}
                class:validField={cityValidation.success}
                class:invalidField={!cityValidation.success}
              />
              {#if !cityValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            {#if !cityValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {cityValidation.err_message}
              </p>
            {/if}
          </div>

          <div class="sm:col-span-2">
            <label
              for="province"
              class="block text-sm font-medium text-gray-700"
            >
              Province / Territory
            </label>
            <div class="mt-1 relative rounded-md shadow-sm ">
              <input
                type="text"
                name="province"
                id="province"
                bind:value={$doc[key].province}
                on:focusout={() => {
                  provinceValidation = Validator.validateProvince(
                    $doc[key].province
                  )
                }}
                class:validField={provinceValidation.success}
                class:invalidField={!provinceValidation.success}
              />
              {#if !provinceValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            {#if !provinceValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {provinceValidation.err_message}
              </p>
            {/if}
          </div>

          <div class="sm:col-span-2">
            <label
              for="postal_code"
              class="block text-sm font-medium text-gray-700"
            >
              Postal Code
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                name="postal_code"
                id="postal_code"
                autocomplete="postal-code"
                bind:value={$doc[key].postal_code}
                class:validField={postalCodeValidation.success}
                class:invalidField={!postalCodeValidation.success}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-y-8 divide-y divide-gray-200">
      <div>
        <div class="pt-8">
          <h1 class="text-l font-semibold text-fiscal-green-500">
            Work Eligibility Information
          </h1>
          <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label
                for="citizenship"
                class="block text-sm font-medium text-gray-700"
              >
                What is your citizenship status?
              </label>
              <div class="mt-1">
                <select
                  id="citizenship"
                  name="citizenship"
                  autocomplete="citizenship"
                  bind:value={$doc[key].citizenship}
                  class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                >
                  <option>Citizen</option>
                  <option>Permanent Resident</option>
                  <option>Working Visa</option>
                  <option>Student Visa</option>
                </select>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label
                for="relocation_preferneces"
                class="block text-sm font-medium text-gray-700"
              >
                Are you willing to relocate for this role?
              </label>
              <div class="mt-1">
                <button
                  type="button"
                  class:btnSelected={!!$doc[key]['will_relocate']}
                  class:btnUnselected={!$doc[key]['will_relocate']}
                  on:click={() => ($doc[key]['will_relocate'] = true)}
                >
                  Yes
                </button>
                <button
                  type="button"
                  class:btnSelected={!$doc[key]['will_relocate']}
                  class:btnUnselected={!!$doc[key]['will_relocate']}
                  on:click={() => ($doc[key]['will_relocate'] = false)}
                >
                  No
                </button>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label
                for="relocation_preferneces"
                class="block text-sm font-medium text-gray-700"
              >
                Where if any, would you be willing to relocate for a role?
              </label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="relocation_preferneces"
                  id="relocation_preferneces"
                  bind:value={$doc[key].relocation_preferences}
                  class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div class="sm:col-span-6">
              <label
                for="relocation_reason"
                class="block text-sm font-medium text-gray-700"
              >
                If you are willing to relocate, why?
              </label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <input
                  type="text"
                  name="relocation_reason"
                  id="relocation_reason"
                  bind:value={$doc[key].relocation_reason}
                  class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div class="sm:col-span-6">
              <label
                for="relocation_preferneces"
                class="block text-sm font-medium text-gray-700"
              >
                What type of job are you looking for?
              </label>
              <div class="mt-1">
                <button
                  type="button"
                  class:btnSelected={!!$doc[key]['jobtype_parttime']}
                  class:btnUnselected={!$doc[key]['jobtype_parttime']}
                  on:click={() =>
                    ($doc[key]['jobtype_parttime'] =
                      !$doc[key]['jobtype_parttime'])}
                >
                  Part Time
                </button>
                <button
                  type="button"
                  class:btnSelected={!!$doc[key]['jobtype_fulltime']}
                  class:btnUnselected={!$doc[key]['jobtype_fulltime']}
                  on:click={() =>
                    ($doc[key]['jobtype_fulltime'] =
                      !$doc[key]['jobtype_fulltime'])}
                >
                  Full Time
                </button>
                <button
                  type="button"
                  class:btnSelected={!!$doc[key]['jobtype_contract']}
                  class:btnUnselected={!$doc[key]['jobtype_contract']}
                  on:click={() =>
                    ($doc[key]['jobtype_contract'] =
                      !$doc[key]['jobtype_contract'])}
                >
                  Contract
                </button>
                <button
                  type="button"
                  class:btnSelected={!!$doc[key]['jobtype_seasonal']}
                  class:btnUnselected={!$doc[key]['jobtype_seasonal']}
                  on:click={() =>
                    ($doc[key]['jobtype_seasonal'] =
                      !$doc[key]['jobtype_seasonal'])}
                >
                  Seasonal
                </button>
              </div>
            </div>

            <div class="sm:col-span-6">
              <label class="block text-sm font-medium text-gray-700">
                When will you be available to start working?
              </label>
              <div class="mt-1 relative rounded-md shadow-sm">
                <DatePicker
                  on:datechange={onDateChange}
                  selected={currentDate}
                />
                <!-- <input
                  type="text"
                  name="year"
                  id="year"
                  placeholder="year"
                  class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 inline-block w-28 sm:text-sm border-gray-300 rounded-md"
                />
                <input
                  type="text"
                  name="month"
                  id="month"
                  placeholder="month"
                  class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 inline-block w-16 sm:text-sm border-gray-300 rounded-md"
                />
                <input
                  type="text"
                  name="day"
                  id="day"
                  placeholder="day"
                  class="shadow-sm focus:ring-fiscal-green-500 focus:border-fiscal-green-500 inline-block w-16 sm:text-sm border-gray-300 rounded-md"
                /> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="space-y-8 divide-y divide-gray-200">
        <div>
          <div class="pt-8">
            <h1 class="text-l font-semibold text-fiscal-green-500">
              Accounting Certifications
            </h1>
            <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-6">
                <label
                  for="accounting_certifications"
                  class="block text-sm font-medium text-gray-700"
                >
                  Which accounting certifications do you currently hold or are
                  working on?
                </label>
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    name="accounting_certifications"
                    id="accounting_certifications"
                    placeholder="CPA, CA, CFP, CGA"
                    bind:value={$doc[key].accounting_certifications}
                    class:validField={accountingCertificationsValidation.success}
                    class:invalidField={!accountingCertificationsValidation.success}
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div>
            <button
              on:click={() => console.log("clicked")}
              class="mt-4 text-sm font-semibold text-fiscal-green-500 flex"
            >
              <h1>Add another certification</h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</form>

<style scoped>.validField {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.validField:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 140, 133, var(--tw-border-opacity));
}

.validField {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.validField:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 140, 133, var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .validField {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.invalidField {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.invalidField:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.invalidField {
  padding-right: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.invalidField::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.invalidField:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.invalidField::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.invalidField:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .invalidField {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.btnSelected {
  display: inline-flex;
  width: 7rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgba(2, 126, 119, var(--tw-bg-opacity));
}

.btnSelected:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 112, 106, var(--tw-bg-opacity));
}

.btnSelected {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btnSelected:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 140, 133, var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
}

.btnUnselected {
  display: inline-flex;
  width: 7rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.btnUnselected:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.btnUnselected {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btnUnselected:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 140, 133, var(--tw-ring-opacity));
  --tw-ring-offset-width: 2px;
}</style>
