<script>
  export let rating = 3
  export let skill = ''
</script>

<div class="flex gap-4 text-gray-600">
  <div class="text-sm font-bold">{skill}</div>
  <div class="flex gap-2">
    <div class:activeRating={rating >= 1} class:inactiveRating={rating < 1} />
    <div class:activeRating={rating >= 2} class:inactiveRating={rating < 2} />
    <div class:activeRating={rating >= 3} class:inactiveRating={rating < 3} />
    <div class:activeRating={rating >= 4} class:inactiveRating={rating < 4} />
    <div class:activeRating={rating >= 5} class:inactiveRating={rating < 5} />
  </div>
</div>

<style scoped>.activeRating {
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(3, 140, 133, var(--tw-bg-opacity));
}

.inactiveRating {
  display: flex;
  height: 1.25rem;
  width: 1.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(53, 163, 157, var(--tw-border-opacity));
}</style>
