<script>
  export let rating = 0

  let setRatingFn = r => {
    return () => {
      if (r == rating) {
        rating = 0
        return
      }

      rating = r
    }
  }
</script>

<div class="flex gap-2 mt-4">
  <button
    on:click={setRatingFn(1)}
    class:activeRating={rating >= 1}
    class:inactiveRating={rating < 1}
  />
  <button
    on:click={setRatingFn(2)}
    class:activeRating={rating >= 2}
    class:inactiveRating={rating < 2}
  />
  <button
    on:click={setRatingFn(3)}
    class:activeRating={rating >= 3}
    class:inactiveRating={rating < 3}
  />
  <button
    on:click={setRatingFn(4)}
    class:activeRating={rating >= 4}
    class:inactiveRating={rating < 4}
  />
  <button
    on:click={setRatingFn(5)}
    class:activeRating={rating >= 5}
    class:inactiveRating={rating < 5}
  />
</div>

<style scoped>.activeRating {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  --tw-bg-opacity: 1;
  background-color: rgba(3, 140, 133, var(--tw-bg-opacity));
}

.inactiveRating {
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 1px;
}</style>
