<script>
  export let print = false
</script>

<div
  class="paperCanvas"
  class:paperCanvas={!print}
  class:printPaperCanvas={!!print}
>
  <slot />
</div>

<style>.paperCanvas {
  box-sizing: border-box;
  padding: 0.3in;
  width: 8.5in;
  height: 11in;
  background-color: #ffffff;
  box-shadow: 0 3px 8px -3px rgba(0, 0, 0, 0.7);
  overflow-y: scroll;
}

.printPaperCanvas {
  size: auto;
  box-sizing: border-box;
  padding: 0.3in;
  width: 8.5in;
  height: 11in;
  background-color: #ffffff;
}</style>
