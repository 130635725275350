<script>
  import Validator, {VALIDATION_SUCCESS} from '../../services/Validator.js'

  export let doc = {}
  export let key = ''

  $doc[key] = $doc[key] || ''

  let placeholder_text = `A resume summary statement is a short paragraph at the beginning of a resume that highlights a job seeker's professional skills and experience. It gives hiring managers a glimpse into the job seeker's expertise before diving into their resume.

  Please aim to keep this statement to less than 200 words. It may seem like you have a lot to cram into 200 words but perfecting your elevator pitch shows that you have a firm grasp on why your experience, goals, and skills make you the perfect fit for the job.`

  $: wordCount = $doc[key].split(' ').length

  const iniial_validation_state = VALIDATION_SUCCESS

  let personalStatementValidation = iniial_validation_state
</script>

<form class="space-y-8 divide-y divide-gray-200">
  <div class="space-y-8 divide-y divide-gray-200">
    <div>
      <div class="pt-8">
        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <label for="about" class="block text-sm font-medium text-gray-700">
              Personal Statement
            </label>
            <div class="mt-1 relative rounded-md shadow-sm">
              <textarea
                id="about"
                name="about"
                placeholder={placeholder_text}
                bind:value={$doc[key]}
                rows="7"
                on:focusout={() => {
                  personalStatementValidation =
                    Validator.validatePersonalStatement($doc[key])
                }}
                class:validField={personalStatementValidation.success}
                class:invalidField={!personalStatementValidation.success}
              />
              {#if !personalStatementValidation.success}
                <div
                  class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                >
                  <!-- Heroicon name: solid/exclamation-circle -->
                  <svg
                    class="h-5 w-5 text-red-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
              {/if}
            </div>
            <div class="flex justify-between">
              <p class="mt-2 text-sm text-gray-500">
                Write a few sentences about yourself.
              </p>
              <p class="mt-2 text-sm text-gray-500">{wordCount} / 200</p>
            </div>
            {#if !personalStatementValidation.success}
              <p class="mt-2 text-sm text-red-600" id="email-error">
                {personalStatementValidation.err_message}
              </p>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<style scoped>.validField {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.validField:focus {
  --tw-border-opacity: 1;
  border-color: rgba(3, 140, 133, var(--tw-border-opacity));
}

.validField {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.validField:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(3, 140, 133, var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .validField {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.invalidField {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}

.invalidField:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}

.invalidField {
  padding-right: 2.5rem;
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}

.invalidField::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.invalidField:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.invalidField::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}

.invalidField:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}

@media (min-width: 640px) {
  .invalidField {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}</style>
